<template>
    <b-form-group>
        <div>
            <b-input-group>
                <b-input-group-prepend>
                    <span v-if="loading && selected" class="mr-2"><b-spinner variant="primary"></b-spinner></span>
                    <b-input-group-text v-else-if="selectNone == false && value == null" class="text-danger">
                        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-input :disabled="true" :value="(selectedItem.title) ? selectedItem.title : `No ${this.title.toLowerCase()} selected.`"></b-input>
                <b-input-group-append>
                    <b-button
                        variant="primary"
                        @click="loadItems()"
                    >
                        {{ (selected) ? "Change" : "Choose" }}
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <b-modal
                v-model="show"
                :title="`Select ${title}`"
                @ok="changeSelected()"
            >
                <div
                    v-if="loading"
                    class="custom-spinner pull-center mt-4"
                ></div>
                <b-list-group v-else>
                    <b-list-group-item v-if="selectNone">
                        <b-radio name="form" v-model="selected" :value="null">None</b-radio>
                    </b-list-group-item>
                    <b-list-group-item
                        v-for="(item, idx) in items"
                        :key="idx"
                    >
                        <b-radio name="form" v-model="selected" :value="item[getKey]">{{ item[displayKey] }}</b-radio>
                    </b-list-group-item>
                    <b-list-group-item
                        v-if="loaded && !items.length"
                    >There are no {{ title }}s to display.</b-list-group-item>
                </b-list-group>
            </b-modal>
        </div>
    </b-form-group>
</template>
<script>
export default {
    name: "select-relationship",
    props: {
        title: String,
        variant: String,
        getSrc: [Function, String],
        listSrc: [Function, String],
        listKey: String,
        itemKey: String,
        getKey: String,
        displayKey: String,
        value: Number,
        selectNone: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.loadSelectedItem();
    },
    data() {
        return {
            selected     : this.value,
            selectedItem : {},
            loading      : false,
            loaded       : false,
            items        : [],
            show         : false
        };
    },
    methods: {
        changeSelected() {
            this.$emit("input", this.selected);
            this.loadSelectedItem();
        },
        loadItems() {
            this.loading = true;
            this.show    = true;
            var src = this.listSrc;
            if (typeof src == "function") src = this.listSrc(this.selected);
            this.$api.get(src).then((response) => {
                this.items   = (response.data[this.listKey] || []);
                this.loading = false;
                this.$emit("onloaditems", this.items);
            });
        },
        loadSelectedItem() {
            if (this.selected != null) {
                this.loading = true;
                var src = this.getSrc;
                if (typeof src == "function") src = this.getSrc(this.selected);
                this.$api.get(src).then((response) => {
                    this.selectedItem = (response.data[this.itemKey] || {});
                    this.loading = false;
                    this.$emit("onloadselected", this.selectedItem);
                });
            } else {
                this.selectedItem = {};
                this.$emit("onloadselected", {});
            }
        }
    }
}
</script>