<template>
    <b-container class="my-4">
        <b-card class="text-center">
            <h2>
                <b-button
                    variant="link"
                    @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                    class="mr-4"
                >
                    <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                </b-button>
                Import Form Responses
            </h2>
            <h1>
                <font-awesome-icon
                    icon="file-invoice"
                    class="mr-3"
                ></font-awesome-icon>
                <font-awesome-icon
                    icon="arrow-right"
                    class="mr-3"
                ></font-awesome-icon>
                <font-awesome-icon
                    icon="chalkboard-teacher"
                ></font-awesome-icon>
            </h1>
        </b-card>
        <b-jumbotron class="my-2">
            <h5>1. Start by selecting a source form.</h5>
            <select-relationship
                title="Form"
                v-model="form_id"
                :get-src="(id) => `/api/forms/${id}/fetchLess`"
                list-src="/api/forms/admin/list"
                list-key="forms"
                item-key="form"
                display-key="title"
                get-key="id"
                class="m-3"
                @onloadselected="loadSchemas()"
            ></select-relationship>
        </b-jumbotron>
        <template v-if="form_id && semester">
            <b-jumbotron class="mt-2">
                <h5>
                    2. Map target columns to source columns.
                </h5>
                <div
                    v-if="loading"
                    class="custom-spinner pull-center mt-4"
                ></div>
                <b-list-group
                    v-else
                    class="mt-4"
                >
                    <b-list-group-item
                        v-for="(field, idx) in schema1_fields"
                        :key="idx"
                    >
                        <b>{{ field.text }}</b>
                        <div class="float-right">
                            <b-select
                                v-model="mapped[field.value]"
                                :options="schema2_fields"
                            ></b-select>
                        </div>
                    </b-list-group-item>
                </b-list-group>
                <div class="text-center mt-3">
                    <b-checkbox
                        class="my-3"
                        v-model="options.remove_responses"
                    >
                        Remove form responses on import.
                    </b-checkbox>
                    <button
                        class="sc-btn sc-btn-lg pill"
                        @click="doImport()"
                    >
                        <font-awesome-icon
                            icon="file-upload"
                            class="mr-1"
                        ></font-awesome-icon>
                        Import
                    </button>
                </div>
            </b-jumbotron>
        </template>
    </b-container>
</template>
<script>
import SelectRelationship from '@/components/SelectRelationship.vue'
import { mapState } from 'vuex';

export default {
    props     : ["import_name"],
    components: { SelectRelationship },
    data() {
        return {
            form_id       : null,
            schema1_fields: [],
            schema2_fields: [],
            mapped: {},
            options: {},
            loading: false
        };
    },
    methods: {
        hasHistory () { return window.history.length > 2 },
        loadSchemas() {
            this.loading = true;
            this.$api.get(
                `/api/admin/import/schemas/classes`,
                { params: {
                    form_id: this.form_id
                } }
            ).then(({ data }) => {
                this.schema1_fields = data.target;
                this.schema2_fields = data.source;
                if (!data.success) {
                    this.$bvToast.toast(data.message, {
                        title:   (data.success) ? "Success" : "Error",
                        variant: (data.success) ? "success" : "danger",
                        autoHideDelay: 5000,
                        appendToast: true
                    });
                }
                this.loading        = false;
            });
        },
        doImport() {
            this.$api.post(
                `/api/admin/import/classes`,
                {
                    form_id         : this.form_id,
                    mapped_columns  : this.mapped,
                    ...this.options
                }
            ).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                if (data.success) this.$router.push(data.redirect);
            }) 
        }
    },
    computed: mapState(["semester"])
}
</script>